.App {
  height: 100vh;
  max-width: 500px;
  margin: auto;
}

.form-submit-btn {
  background: white !important;
  color: var(--OT_darkgreen);
  height: 40px;
  border: none;
  font-weight: 600;
  width: 100% !important;
  font-size: 18px;
  margin-top: -30px;
}

.form-submit-btn.auth {
  background-color: var(--OT_darkgreen) !important;
  color: white !important;
}

.form-submit-btn.gradient {
  background: linear-gradient(90deg, #00a94f 0%, #77bc1f 100%) !important;
  margin-top: 0;
  color: white !important;
}

.form-submit-btn.ot {
  width: 45%;
  background-color: transparent !important;
  border: 2px solid white;
  color: white !important;
  border-radius: 15px;
  padding: 10px 0;
  /* margin-top: 10px; */
  margin: 0;
}

.form-submit-btn.omron {
  background: var(--omron_blue) !important;
  color: white !important;
}

.form-submit-btn.border {
  background: transparent !important;
  border: 2px solid var(--OT_darkgreen);
  color: var(--OT_darkgreen);
}

.form-submit-btn.border-omron {
  background: transparent !important;
  border: 2px solid var(--omron_blue);
  color: var(--omron_blue);
}

.form-submit-btn.border.home {
  background: transparent !important;
  color: var(--OT_darkgreen);
  margin-top: 10px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  width: 100% !important;
  border: none !important;
}

.form-submit-btn.border.home::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 4px;
  padding: 1px;
  z-index: -1;
  background: linear-gradient(90deg, #00a94f 0%, #77bc1f 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.form-submit-btn.home {
  width: 90% !important;
  font-size: 18px;
  color: white;
  background: var(--omron_blue) !important;
  margin: 20px auto 10px;
  display: block;
}

.form-submit-btn.home-pink {
  width: 90% !important;
  color: white;
  background: #f05b83 !important;
  margin: 20px auto 10px;
  display: block;
}

.fixed-whatsapp {
  position: fixed;
  z-index: 100;
  top: 50%;
  right: 0;
}

.form-submit-btn.reward {
  font-size: 18px;
  width: 45% !important;
  color: var(--omron_blue);
  background: white !important;
  border: 2px solid var(--omron_blue);
  margin: 5px auto ;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

}
.form-submit-btn.reward-pink {
  width: 90% !important;
  color: #f05b83;
  background: transparent !important;
  border: 1px solid #f05b83;
  margin: 10px auto;
}

.font {
  font-weight: 500;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
  "Lucida Sans", Arial, sans-serif;
}

.form-submit-btn.faq-tnc {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  width: 90% !important;
  color: #f05b83;
  background: transparent !important;
  border: 1px solid #f05b83;
  display: block;
  /* margin-top: 0px; */
  margin: 0 auto;
}

.form-input:focus {
  border: 1px solid #3d3d3d;
  font-weight: 700;
}

.verify-loading-btn {
  border: none;
  color: var(--OT_darkgreen);
  font-size: 40px;
}

.reward-card {
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.16);
  padding: 15px;
  background-color: white;
  margin-bottom: 20px;
  border-radius: 10px;
}

.text-grid {
  display: grid;
  grid-template-rows: repeat(3, 30px);
  grid-template-columns: repeat(2, auto);
  font-size: 12px;
  font-weight: 700;
}

.upload-receipt-input-file-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  color: black;
  border-radius: 10px;
  height: 140px;
  margin: 20px auto;
  width: 100%;
  box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.13);
  position: relative;
}

.info {
  position: absolute;
  bottom: 5%;
  right: 3%;
}

.brand-card {
  display: flex;
  box-shadow: 4px 4px 9px #cddde5;
  border-radius: 25px;
  width: 100%;
  max-width: 100%;
  max-height: 160px;
  border: none;
  background-color: white;
}

.brand-card.blue-border {
  border: 2px solid var(--omron_blue);
}

/* .blue-omron {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  width: 100%;
  max-height: 680px;
} */

.pink-omron {
  height: fit-content;
}

.ot-view {
   background: #74c371;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  width: 100%;
  padding-bottom: 20px;
  height: 100vh;
  background-image: url('./assets/Group 22748(1).png');
  background-position: bottom;
}

.receipt-card {
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  color: black;
  display: flex;
  margin: 10px 0;
  padding: 10px;
  background-color: #f5f5f5;
}

.rejected-receipt {
  margin-top: 5px;
  background-color: #c4113c;
  padding: 8px 15px;
  border-radius: 10px;
  border: none;
  width: 100%;
  color: white;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}


.form-submit-btn.grey {
  /* margin-top: 10px; */
  width: 100% !important;
  background: #dfd9cf !important;
  color: #7e7e7e;
}

.form-submit-btn.home.grey {
  width: 90% !important;
}
.form-submit-btn.home-pink.grey {
  width: 90% !important;
}

.modal-btn {
  background: var(--OT_lightgreen) !important;
  color: white;
  border: none;
  font-weight: 600;
  width: 50% !important;
  font-size: 16px;
  display: block;
  margin: 10px auto 0;
}

.modal-btn.dark {
  background: var(--OT_darkgreen) !important;
}

.modal-btn.omron {
  background: var(--omron_blue) !important;
}

.ant-modal .ant-modal-content {
  padding: 20px 10px;
}

.ant-input .ant-picker {
  background: #f6f6f6 !important;
}
/* 
.placeholder{
  color: #7e7e7e;
  position: relative;
  top: -30px;
  padding-left: 10px;
}

.placeholder:after{
  content: '*';
  color: red
}

.receipt-input:focus + .placeholder { 
  display: none;
} */

/* 
.ant-select-selection-item {
  color: red !important;
}
*/

/* .ant-select-selection-placeholder {
  color: blue !important;
}  */